import { DefaultTheme } from 'components/Layout/styles';
/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { getUser, setUser } from 'utils/auth';
import { createStore, StateMachineProvider } from 'little-state-machine';

export const UserContext = createContext({ user: null, setUser: () => {} });
UserContext.displayName = 'UserContext';

// eslint-disable-next-line react/display-name

const Wrapper = ({ element }) => {
  let [user, setStateUser] = useState(() => getUser()); // get user from local storage simply for now, later do validation if auth token is valid in the backend.

  useEffect(() => {
    setUser(getUser());
    console.log('User changed', user);
  }, [user]);

  const log = store => {
    // logging for a little state machine, for now it's just console  log
    console.log(store);
    return store;
  };

  createStore(
    {
      propertyRequest: {}, // it's an object of your state
      mailchimpRequest: {},
      isLoading: false,
      dashboard: { isDropdownExpanded: false },
    },
    {
      name: 'root', // rename the store
      middleWares: [log], // function to invoke each action
      //  storageType?: Storage; // session/local storage (default to session)
    },
  );

  return (
    <StateMachineProvider>
      <ThemeProvider theme={DefaultTheme}>
        <UserContext.Provider
          value={{
            user: user,
            setUser: u => {
              setStateUser(u);
              setUser(u);
            },
          }}
        >
          {element}
        </UserContext.Provider>
      </ThemeProvider>
    </StateMachineProvider>
  );
};

// eslint-disable-next-line react/display-name
export default ({ element }) => <Wrapper element={element} />;
