import { ToastContainer } from 'react-toastify';
import styled, { createGlobalStyle } from 'styled-components';
import { mediaBreakpointDown, mediaBreakpointUp } from 'utils/styledCssHelpers';

export const calcResize = (min, max) =>
  `calc(${min}px + (${max} - ${min}) * (100vw - 320px) / (2560 - 320))`;

export const DefaultTheme = {
  fontFamily: 'Basis Grotesque, sans-serif',
  shadow: '0px 3px 6px rgba(0, 0, 0, 0.15)',

  colors: {
    navy_blue: '#0061df',
    main: '#192F54',
    primary: '#325DA5',
    primary_transparent: 'rgb(50, 93, 165, 0.2)',
    accent: '#E85270',
    accent_dark: '#e52f53',
    light_blue: '#C1D0EA',
    light_grey: '#F9FAFD',
    dark_grey: '#696969',
    alice_blue: '#f5f7fb',
    alice_blue_2: '#F2F7FE', // https://www.htmlcsscolor.com/hex/F2F7FE
    grey: '#E6E9EC',
    tangaroa: '#0d263b', // Can't find a name, looked up here: https://www.htmlcsscolor.com/hex/0D263B
    p: '#192F54',
    h: '#192F54',
    lead: '#325DA5',
    a: '#E85270',
    green: '#06e24a',
    lime_green: '#2DB744',
    error: '#bf1650',
    light_accent: '#bebebe',
    lavender: '#E6E7ED',
    spindle: '#B0BAC9',
    solitude: '#E0E7F5',
    rock_blue: '#8B9BAF',
    bali_hai: '#8798AD',
    echo_blue: '#A5ADC6',
    white: '#ffffff',
    rightmove: '#00deb6',
    zoopla: '#8046f2',
    bg_grey: '#f1f3f9',
    pastel_blue: '#92ADDC',
    vista_blue: '${props => props.theme.colors.pastel_blue} ',
    light_gray: '#F5F6FA',
  },
  breakpoints: {
    xs: '0',
    mobile: '425',
    laptop: '1024',
    desktop: '1440',
    hd: '2560',
  },
  fontSizes: {
    lead: `${calcResize(16, 20)}`,
    main: `${calcResize(16, 20)}`,
    title: '1rem',
    p: `${calcResize(16, 20)}`,
    span: `${calcResize(16, 20)}`,
    h1: `${calcResize(48, 70)}`,
    h2: `${calcResize(36, 60)}`,
    h3: `${calcResize(28, 46)}`,
    h4: `${calcResize(24, 32)}`,
    h5: `${calcResize(15, 17)}`,
    h6: `${calcResize(12, 15)}`,
    button: '15px',
  },
};

const clearfix = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  // marginBottom: '15px',
};

const headingClearfix = {
  fontWeight: 'bold',
  textRendering: 'optimizeLegibility',
  lineHeight: 1.1,
  letterSpacing: -0.92,
};

export const GlobalStyle = createGlobalStyle`
  html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${props => props.theme.colors.main};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.main};
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}
p {
  ${clearfix}
  color: ${props => props.theme.colors.p};
  font-size: ${props => props.theme.fontSizes.p};
  letter-spacing: -0.4px;
}
span {
  ${clearfix}
  color: ${props => props.theme.colors.p};
  font-size: ${props => props.theme.fontSizes.span};
  letter-spacing: -0.4px;
}
h1 {
  ${clearfix}
  ${headingClearfix}
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h1}
}
h2 {
  ${clearfix}
  ${headingClearfix}
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h2}
}
h3 {
  ${clearfix}
  ${headingClearfix}
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h3}
}
h4 {
  ${clearfix}
  ${headingClearfix}
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h4}
}
h5 {
  ${clearfix}
  ${headingClearfix}
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h5}
}
h6 {
  ${clearfix}
  ${headingClearfix}
  color: ${props => props.theme.colors.accent};
  font-size: ${props => props.theme.fontSizes.h6};
  text-transform: uppercase;
}
a{
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  color: ${props => props.theme.colors.a};
  letter-spacing: -0.4px;
}
a:active,
a:hover {
  outline-width: 0;
  text-decoration: underline;
  color: ${props => props.theme.colors.a};
}
.lead {
  color: ${props => props.theme.colors.lead};
  font-size: ${props => props.theme.fontSizes.lead};
  letter-spacing: -0.4px;
}

input {
  ::placeholder {
    color: ${props => props.theme.colors.main};
    opacity: 0.6;
    font-size: 16px;
  }
}

  div[class*="col"] {
    position: relative !important;
  }

.form-control {
  &:focus {
    border-color: ${props => props.theme.colors.accent};
    box-shadow: none;
  }
}

.form-label {
  font-size:16px;
}

@media (min-width: 2560px){
.container {
    max-width: 1920px;
}}


.single-blog-post-content {

  /* These css below we need to extract to a common place to re-use for both wordpress posts and wordpress pages  */

  h1 {font-size: 42px;}
  h2 {font-size: 36px;}
  h3 {font-size: 28px;}
  h4 {font-size: 24px;}
  h5 {font-size: 15px;}
  h6 {font-size: 12px;}

  a{
  background-color: transparent!important;
  -webkit-text-decoration-skip: objects!important;
  text-decoration: none!important;
  color: ${props => props.theme.colors.a}!important;
  letter-spacing: -0.4px!important;
}
a:active,
a:hover {
  outline-width: 0!important;
  text-decoration: underline!important;
  color: ${props => props.theme.colors.a}!important;
}

  table {
    width: 100%;
    border: 1px solid #dee2e6;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;

    tr {
      border-color: inherit;
    }
    th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
    }

    td, th {
      border: 1px solid #dee2e6;
      padding: .75rem;
    }
  }

  .gatsby-image-wrapper {
    width: 100% !important;
    border-radius: 7px;
  }

  .osl-image-wrapper {
    width: 100%;
  }

  figure.wp-caption {
    width: 100% !important;
  }

  ${mediaBreakpointDown(
    'md',
    `
  iframe.giphy-embed {
    width: 100%;
    height: auto;
  }
  `,
  )}
}


  .gatsby-focus-wrapper {
    width: 100%;
    position: relative;
  }

  .video-modal {
    & .modal-content {
      background: transparent;
      border: 0;
      align-items: center;
    }
  }
`;

export const SToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background-color: ${props => props.theme.colors.error};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${props => props.theme.colors.green};
  }
  .Toastify__toast-body {
    padding: 15px;
    font-size: 16px;
  }
  .Toastify__progress-bar {
  }
`;

export const SpacedTitle = styled.span`
  font-weight: 500;
  text-transform: uppercase;
  color: ${props =>
    props.colorVariant
      ? (props.colorVariant === 'accentDark' && props.theme.colors.accent) ||
        (props.colorVariant === 'main' && props.theme.colors.main)
      : props.theme.colors.light_blue};
  font-size: ${calcResize(12, 16)};
  letter-spacing: 4px;
`;

export const SpacedTitleH1 = styled.h1`
  font-weight: 500;
  text-transform: uppercase;
  color: ${props =>
    props.colorVariant
      ? (props.colorVariant === 'accentDark' && props.theme.colors.accent) ||
        (props.colorVariant === 'main' && props.theme.colors.main)
      : props.theme.colors.light_blue};
  font-size: ${calcResize(12, 16)};
  letter-spacing: 4px;
`;

export const SDashboardTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  color: ${props => props.theme.colors.bali_hai};
  display: flex;
  justify-content: space-between;
`;
