/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import 'jquery/dist/jquery.min.js';
// import '@popperjs/dist/esm/popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.css';
import wrapWithProvider from './src/wrap-with-provider';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import './src/styles/global.css';
import { navigate } from 'gatsby';

export const wrapRootElement = wrapWithProvider;

const handleAreaGuide = () => {
  console.log('Area Guides');
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Attaches event to the CTA button
  const ctaBtn = document.getElementById('ctaBtn');
  if (ctaBtn) {
    ctaBtn.addEventListener('click', e => {
      // Here, just ensuring the event
      const url = e.currentTarget.getAttribute('data-category-url');
      navigate(url);
    });
  }
};
