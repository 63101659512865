const AUTH_USER = 'user';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem(AUTH_USER)
    ? JSON.parse(window.localStorage.getItem(AUTH_USER))
    : null;

export const setUser = user => {
  window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
  window.dataLayer = window.dataLayer || [];
  if (user) {
    window.dataLayer.push({
      user_properties: {
        user_id: user.id,
        email: user.email,
      },
    });
  }
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user;
};

export const clearUser = () => localStorage.removeItem(AUTH_USER);
