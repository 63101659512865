const breakpointDict = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920,
  xxxl: 2560,
};

const mediaBreakpoint = (bx, isUp, cssText) => {
  /**
   * This is the main function that is responsible for generation of responsive css like
     @media only screen and (max-width: 767px) {
       margin-bottom: 30px;
      text-align: center;
      }

      what i have done is noticed how bootstrap does it https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints  

      notice that up and down are slightly diff by 0.02 of breakpoints. then I define the breakpointDict dict and if passed
      
      mediaBreakpoint("md", false, "margin-bottom: 30px;text-align: center;") ... this will basically create the @media css - without having to remember the pixel number to put and avoiding errors
      // as of now the VSCode plugin that makes style component jsx show up in color-coding will not work for the 'margin-bottom:30px' bit, maybe we can later patch and make our own version of this plugin to go around it


    I have also made 2 helper functions mediaBreakpointUp and mediaBreakpointDown to go along with it, so usually we wont call this main function but the up and down ones only
   */

  const breakpointPx = breakpointDict[bx];
  const finalBreakpointPx = isUp ? breakpointPx : breakpointPx - 0.02; //https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
  const minMaxWord = isUp ? 'min' : 'max';

  return `
  @media only screen and (${minMaxWord}-width: ${finalBreakpointPx}px) {
    ${cssText}  
  }
  `;
};

export const mediaBreakpointUp = (breakPoint, cssText) =>
  mediaBreakpoint(breakPoint, true, cssText);
export const mediaBreakpointDown = (breakPoint, cssText) =>
  mediaBreakpoint(breakPoint, false, cssText);
